import { proxy } from 'valtio'
import type { pricetrade } from '~/trades/indicators/asiajye/pricetrade'

export type StrategyType =
  | 'gaptrade'
  | 'gaptrade'
  | 'daytrade'
  | 'bandtrade'
  | 'turning1'
  | 'turning2'
  | 'turning3'
  | 'turning4'
  | 'turning5'
  | 'turning6'

export type StrategyNameType =
  | '閃電多空'
  | '探極當沖'
  | '探極波段'
  | '轉折多空'
  | '短線轉折'
  | '短波轉折'
  | '天地線2'
  | '長波轉折'

export type TurningValue =
  | [0.99667, 1.00333]
  | [0.99667, 1.00333]
  | [0.994745, 1.005255]
  | [0.990855, 1.009145]
  | [0.98889, 1.01111]

/**
 * # agent 層級的 root store
 *
 * ## 統一管理這一層次的所有客製值
 */
export const asiajyeStore = proxy({
  /** # 指標「{@link pricetrade}」內部會使用的值 */
  turningTrade: {
    prices: [] as number[],
    position: 0,
    priceValue: 0,
    assistPriceValue: 0,
    turningMP: 1,
  },
})
