import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const userkd = createIndicator({
  displayName: 'KD',
  id: 'userkd',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const i = this._input(0)
      const o = this._input(1)
      const r = this._input(2)

      const vtime = this.PineJS.Std.time(this._context)
      const vtime_array = this._context.new_var(vtime)

      const s = this.PineJS.Std.close(this._context)
      const a = this.PineJS.Std.high(this._context)
      const l = this.PineJS.Std.low(this._context)

      const c = this._context.new_var(s)
      const u = this._context.new_var(a)
      const h = this._context.new_var(l)

      const d = this.PineJS.Std.stoch(c, u, h, i, this._context)
      const p = this._context.new_var(d)
      const g = this.PineJS.Std.sma(p, o, this._context)
      const f = this._context.new_var(g)
      //高
      const vHn = this.PineJS.Std.highest(u, i, this._context)
      //低
      const vLn = this.PineJS.Std.lowest(h, i, this._context)
      //RSV
      const vrsv = ((s - vLn) / (vHn - vLn)) * 100
      const vrsv_array = this._context.new_var(vrsv)
      //K%
      const vk = this.PineJS.Std.ema(vrsv_array, o, this._context)
      const vk_array = this._context.new_var(vk)
      //D%
      const vd = this.PineJS.Std.ema(vk_array, r, this._context)
      const vd_array = this._context.new_var(vd)

      return [vk, vd]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#0099ee',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 35,
          visible: !0,
          color: '#ff4433',
        },
      },
      precision: 4,
      inputs: {
        in_0: 9,
        in_1: 3,
        in_2: 3,
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: '%K',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: '%D',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !1,
    inputs: [
      {
        id: 'in_0',
        name: 'length',
        defval: 14,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
      {
        id: 'in_1',
        name: 'smoothK',
        defval: 1,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
      {
        id: 'in_2',
        name: 'smoothD',
        defval: 3,
        type: 'integer',
        min: 1,
        max: 1e4,
      },
    ],
    scriptIdPart: '',
  },
})
